
$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('.menuresponsive').animate({ height: '0' }, 500, function(){
                $('.menuresponsive').css({'visibility': 'hidden', 'overflow': 'hidden'});
            });
        }else{
            $(this).addClass('open');
            $('.menuresponsive').css({'visibility': 'visible', 'overflow': 'auto'});
            autoHeightAnimate($('.menuresponsive'), 500);
        }
    });


    //TALEN VISIBLE AFTER LOAD
    $('.talen').css({'visibility': 'visible'});


    //SEARCH FORM BUTTON
    $('form.sidemenu button, form.search button').click(function(){
        $(this).parent().submit();
    });


    //SIDEBAR + MAIN + TOPMENU HEIGHTS
    function topmenuheight(){
        var topmenuheight = $('nav.navigation').outerHeight();
        if ($('nav.navigation .menuresponsive').is(':visible')){
            var responsivemenu = $('nav.navigation .menuresponsive').outerHeight();
        }else{
            var responsivemenu = 0;
        }
        var heightneeded = topmenuheight - responsivemenu;
        $('.container-fluid > .sidebar').css({'top': heightneeded, 'min-height': 'calc(100% - '+heightneeded+'px)'});
        $('.container-fluid > main').css({'top': heightneeded, 'min-height': 'calc(100% - '+heightneeded+'px)'});
        $('.sidebar .menu ul').css({'min-height': 'calc(100% - ('+heightneeded+'px + 30px))'});
    }
    topmenuheight();
    $(window).resize(function(){
        topmenuheight();
    });
});



equalheight = function(container){
var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {
   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;
   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}
$(window).load(function() {
    $('nav.navigation ul.menu > li').css({'visibility': 'visible'});
    equalheight('nav.navigation ul.menu > li > a');
    equalheight('.single_attachment .equalheight');
});
$(window).resize(function(){
    equalheight('nav.navigation ul.menu > li > a');
    equalheight('.single_attachment .equalheight');
});



/* Function to animate height: auto */
function autoHeightAnimate(element, time){
  	var curHeight = element.height(), // Get Default Height
        autoHeight = element.css('height', 'auto').height(); // Get Auto Height
    element.height(curHeight); // Reset to Default Height
    element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
}
